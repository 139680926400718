.services {
  width: 100%;
  padding: 45rem 0 10rem;
  background-color: #161616;
  margin-top: -40rem;
  display: flex;
  justify-content: center;

  @include respond(phone) {
    flex-direction: column;
  }

  &.services__animation .services__design {
    animation: borderFromRight 1s ease-out forwards;
  }

  &__container {
    margin: 0 10rem;
    max-width: 1440px;
    display: flex;
    gap: 2rem;

    @include respond(tab-port) {
      margin: 0 3rem;
    }

    @include respond(phone) {
      flex-direction: column;
    }
  }

  &__col {
    flex-basis: 33.33%;
    display: flex;
    flex-direction: column;
    min-height: 130rem;

    @include respond(big-desktop) {
      min-height: 110rem;
    }

    @include respond(tab-land) {
      min-height: 90rem;
    }

    @include respond(tab-port) {
      min-height: 80rem;
    }

    @include respond(phone) {
      min-height: auto;
    }

    &--3 {
      & .services__section--info {
        margin-top: 12rem;

        @include respond(phone) {
          margin-top: 0;
        }
      }

      & .services__section--empty {
        background-color: $color-primary;
        color: $color-dark-2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        transition: all 200ms ease-in-out;
        text-decoration: none;
        cursor: pointer;

        @include respond(tab-land) {
          font-size: 2rem;
        }

        @include respond(phone) {
          margin-top: 2rem;
        }

        & span {
          display: inline-block;
          margin-left: 1rem;
          font-size: 6rem;

          @include respond(tab-land) {
            font-size: 4rem;
          }
        }

        &:hover {
          font-size: 3.2rem;

          @include respond(tab-land) {
            font-size: 2.2rem;
          }
        }
      }
    }
  }

  &__heading {
    font-family: Akira, sans-serif;
    font-size: 6rem;
    text-transform: uppercase;

    @include respond(tab-land) {
      font-size: clamp(3.5rem, 4vw, 5rem);
    }

    @include respond(phone) {
      display: none;
    }

    & span {
      display: block;
    }

    &--small {
      font-family: Akira, sans-serif;
      text-transform: uppercase;
      font-size: 3.5rem;
      display: none;

      @include respond(phone) {
        display: block;
      }
    }
  }

  &__section {
    background-color: #26272a;
    border-radius: 2.5rem;
    padding: 4rem;

    @include respond(tab-port) {
      padding: 2.5rem 2rem;
    }

    &:not(:last-of-type) {
      margin-bottom: 2rem;

      @include respond(phone) {
        margin-bottom: 0;
      }
    }

    &--heading {
      font-size: 5rem;
      color: $color-primary;
      margin-bottom: 1rem;

      @include respond(big-desktop) {
        font-size: 4rem;
      }

      @include respond(tab-port) {
        font-size: 3rem;
      }
    }

    &--subheading {
      font-size: 3.5rem;
      text-transform: uppercase;
      margin-bottom: 1rem;

      @include respond(big-desktop) {
        font-size: 2.5rem;
      }

      @include respond(tab-land) {
        font-size: 2rem;
      }

      @include respond(tab-port) {
        font-size: 1.5rem;
      }
    }

    &--info {
      font-size: 3rem;

      @include respond(big-desktop) {
        font-size: 2.5rem;
      }

      @include respond(tab-land) {
        font-size: 1.7rem;
      }

      @include respond(tab-port) {
        font-size: 1.5rem;
      }
    }

    &--empty {
      flex-grow: 1;

      @include respond(phone) {
        display: none;
      }
    }
  }

  &__design {
    // height: 2rem;
    border-bottom: 10px solid $color-primary;
    margin-bottom: 2rem;
    width: 0;
    transform-origin: right;

    @include respond(phone) {
      display: none;
    }
  }
}
