.footer {
  display: flex;
  justify-content: center;
  //   height: 60vh;
  // background-image: linear-gradient(to bottom, $color-dark-3 45%, #1a1a1a);
  // background-size: contain;
  // background-repeat: no-repeat;
  background-color: $color-primary;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  //   &__head {
  //     height: 10rem;
  //     width: 100%;
  //     display: flex;
  //     justify-content: center;
  //     // background-color: aliceblue;
  //   }

  &__heading {
    font-family: Akira, sans-serif;
    font-size: 8rem;
    text-align: center;

    @include respond(tab-land) {
      font-size: 6rem;
    }

    @include respond(tab-port) {
      font-size: 4.5rem;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7rem 0;
    width: 40%;

    @include respond(tab-land) {
      width: 60%;
    }

    @include respond(phone) {
      width: 80%;
    }
  }

  &__list {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: space-between;
    // color: $color-primary;
    color: $color-dark-2;
    padding: 0 2rem 3rem;
    border-bottom: 2px solid $color-dark-1;

    @include respond(phone) {
      flex-direction: column;
    }

    &--item {
      @include respond(phone) {
        &:not(:first-child) {
          margin-top: 2rem;
        }
      }
      & a {
        color: inherit;
        text-decoration: none;
        transition: all 100ms ease-in-out;

        &:hover {
          color: $color-primary-fade;
        }
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }

  &__links {
    width: 50%;
    padding: 3rem 3rem 0;
    display: flex;
    justify-content: space-between;
    // color: $color-text;

    @include respond(phone) {
      padding: 3rem 0;
      align-self: flex-start;
    }

    &--item {
      height: 4rem;
      width: 4rem;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      // background-color: $color-primary;
      background-color: $color-dark-2;

      &:not(:last-child) {
        margin-right: 2rem;
      };
      & img{
        width: 65%;
        aspect-ratio: 1;
      }
    }
  }

  &__banner {
    // background-color: $color-primary;
    background-image: linear-gradient(to bottom, $color-dark-3 45%, #1a1a1a);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    padding: 1.5rem 0;
    text-align: center;
    // color: #000000;

    &--head {
      text-transform: uppercase;
      font-weight: 100;
      font-size: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      & span {
        margin-right: 1rem;
        font-size: 1rem;
        font-weight: 900;
      }
    }
  }
}
