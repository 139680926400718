.what {
  width: 100%;
  padding: 20rem 0;
  display: flex;
  justify-content: center;
  background-color: $color-text;
  color: #000000;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
  position: relative;

  @include respond(tab-port) {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
  }

  //   @include respond(phone) {
  //     padding: 10rem 0;
  //   }

  &.what__animation .what__heading span::after {
    animation: borderFromRight 1s ease-out forwards;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // width: 200px;
    height: 20rem;
    // z-index: 3;
    background-color: $color-primary;
    clip-path: path("M -2 200 Q 200 -200 1920 200 L 1920 -2 L -2 -2 Z");
    // clip-path: path("M 0 100 Q 200 -100 1920 100 Z");
  }

  &__container {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10rem;
    position: relative;

    @include respond(tab-port) {
      flex-direction: column;
      margin: 0 5rem;
    }

    @include respond(phone) {
      margin: 0 3rem;
    }
  }

  &__heading {
    font-family: Akira;
    font-size: 6rem;
    text-transform: uppercase;
    margin-bottom: 4rem;

    @include respond(tab-port) {
      font-size: 5rem;
    }

    @include respond(tab-port) {
      font-size: 4rem;
    }

    @include respond(phone) {
      font-size: 3.5rem;
    }

    & span {
      display: inline-block;
      padding-left: 3rem;
      // border-bottom: 1rem solid $color-primary;
      transform: translateX(-1.5rem);
      position: relative;
      transform-origin: right;

      &::after {
        content: "";
        position: absolute;
        bottom: -1rem;
        right: 0;
        width: 0;
        height: 1rem;
        background-color: $color-primary;
        transform-origin: right;
      }
    }
  }

  &__carousel {
    max-width: 70rem;
    overflow: hidden;

    @include respond(tab-port) {
      width: 80%;
    }

    @include respond(tab-port) {
      width: 90%;
    }
  }

  &__content {
    &--container {
      display: flex;
      transition: all 300ms ease-in-out;
      margin-bottom: 8rem;
    }

    &--item {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 300ms ease-in-out;
    }
  }

  &__img {
    min-height: 10rem;
    width: 10rem;
    border: 3px solid $color-primary;
    border-radius: 50%;
    margin-bottom: 2rem;
  }

  &__details {
    // max-width: 700px;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 2rem;

    @include respond(tab-port) {
      font-size: 2.5rem;
    }

    @include respond(phone) {
      font-size: 2rem;
    }
  }

  &__signatures {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__name {
    font-size: 2.2rem;
    color: $color-primary;
  }

  &__position {
    font-weight: bold;
    color: #707070;
  }

  &__controls {
    position: absolute;
    top: 50%;
    z-index: 3;
    width: 60%;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 100em) {
      width: 70%;
    }

    @include respond(tab-land) {
      width: 95%;
    }

    @include respond(tab-port) {
      width: 100%;
    }
  }
}
