.orange {
  width: 100%;
  padding: $section-padding-2;
  display: flex;
  justify-content: center;
  background-color: $color-primary;
  // z-index: 100;
  position: relative;
  padding-bottom: 20rem;

  @include respond(phone) {
    overflow: hidden;
  }

  &.orange__animation .orange__header--design {
    animation: growFromLeft 1s ease-out forwards;
  }

  &__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 10rem;

    @include respond(tab-port) {
      flex-direction: column;
      margin: 0 5rem;
    }
  }

  &__header {
    font-family: Akira;
    font-size: 6rem;
    text-transform: uppercase;
    display: flex;
    margin-bottom: 5rem;

    @include respond(tab-land) {
      font-size: 4rem;
    }

    @include respond(tab-port) {
      font-size: 3rem;
    }

    &--design {
      // flex-basis: auto;
      // flex-grow: 1;
      border-bottom: 1.5rem solid #ffffff;
      margin-bottom: 1.35rem;
      margin-left: 1rem;
      width: 0;
      transform-origin: left;

      @include respond(tab-land) {
        border-width: 1rem;
        margin-bottom: 0.9rem;
      }

      @include respond(tab-port) {
        border-width: 0.8rem;
        margin-bottom: 0.7rem;
      }
    }
  }

  &__heading {
    & span {
      display: block;
    }
  }

  &__clients {
    // Client variables
    $gap-big: 5rem;
    $gap-med: 2.5rem;
    $border: 1.5rem;

    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: $gap-big;

    @include respond(tab-land) {
      gap: $gap-med;
    }

    &__item {
      flex-basis: calc(33% - #{$gap-big});
      height: 30rem;
      position: relative;
      background-color: #ffffff19;
      backdrop-filter: blur(30px);
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond(tab-land) {
        flex-basis: calc(33% - #{$gap-med});
      }

      @media only screen and (max-width: 48em) {
        flex-basis: calc(50% - #{$gap-med});
      }

      @include respond(phone) {
        flex-basis: 100%;
      }

      & img {
        width: 90%;
        height: 85%;
        object-fit: contain;
      }

      // & svg {
      //   position: absolute;
      //   top: 0px;
      //   left: 0px;
      //   height: 100%;
      //   width: 100%;
      // }

      // & path {
      //   fill: none;
      //   stroke: #fff;
      //   stroke-width: $border;
      //   stroke-dasharray: 60, 10;
      // }

      // & span {
      //   position: absolute;
      //   top: 0px;
      //   left: 0px;
      //   padding: 10px;
      // }
    }
  }

  &__bar {
    position: absolute;
    width: 5rem;
    height: 120%;
    background-color: $color-primary-fade2;
    transform: rotate(-25deg) translateX(-15rem) translateY(-10rem);
    z-index: 1;
    // margin-left: 2rem;

    @include respond(tab-land) {
      transform: rotate(-30deg) translateX(-17rem) translateY(-10rem);
    }

    @include respond(phone) {
      height: 112%;
    }

    &--1 {
      top: 0;
      left: 0;
    }

    &--2 {
      top: 0;
      left: 10rem;
    }

    &--3 {
      top: 0;
      left: 20rem;
    }

    &--4 {
      top: 0;
      left: 30rem;
    }

    &--5 {
      top: 0;
      left: 40rem;
    }
  }
}
