.nav {
  width: 100%;
  // background-color: aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  height: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  transition: all 0.2s ease-in-out;

  @include respond(tab-land) {
    font-size: 2rem;
  }

  &__scrolled {
    position: sticky;
    background-color: $color-bg;
  }

  &__options {
    display: flex;
    justify-content: center;
    width: 70%;

    @include respond(tab-land) {
      width: 90%;
    }
  }

  &__logo {
    width: 10em;
    // background-color: beige;
    display: flex;
    align-items: center;
    // margin-right: 4em;

    &--img {
      width: 100%;
    }
  }

  &__link {
    display: block;
    text-decoration: none;
    color: inherit;

    &:hover,
    &:active {
      color: $color-text;
    }

    // &:link, &:visited {

    // }
  }

  &__list {
    color: $color-primary;
    // background-color: aqua;
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__small {
    width: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 2em;
    position: fixed;
    background-image: linear-gradient(to bottom, $color-bg 55%, #00000060);

    &--logo {
      height: 5rem;
      width: 12em;
      display: flex;
      align-items: center;
      margin: 0 3rem;

      & img {
        width: 100%;
        height: auto;
      }
    }

    &--btn {
      background-color: $color-primary;
      box-shadow: 2px 2px 20px #2e2e2e;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1000;
      position: relative;

      & .bar {
        height: $nav-btn-h;
        width: $nav-btn-w;
        display: block;
        margin: 10px auto;
        // position: relative;
        background-color: #fff;
        // -moz-border-radius: 10px;
        // -webkit-border-radius: 10px;
        border-radius: 10px;
        // -moz-transition: 0.4s;
        // -o-transition: 0.4s;
        // -webkit-transition: 0.4s;
        transition: 0.4s;
        position: absolute;

        &:nth-of-type(1) {
          top: 4px;
          -moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
          -o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
          -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
          -webkit-transition-delay: 0.3s, 0.1s;
          transition-delay: 0.3s, 0.1s;
          transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
        }

        &:nth-last-of-type(2) {
          top: 14px;
          -moz-transition: ease 0.3s 0.3s;
          -o-transition: ease 0.3s 0.3s;
          -webkit-transition: ease 0.3s;
          -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
          transition: ease 0.3s 0.3s;
        }

        &:nth-of-type(3) {
          top: 24px;
          -moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
          -o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
          -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
          -webkit-transition-delay: 0.3s, 0.1s;
          transition-delay: 0.3s, 0.1s;
          transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
        }
      }

      &[data-mode="close"] {
        -moz-transition: -moz-transform 0.3s ease 0.5s;
        -o-transition: -o-transform 0.3s ease 0.5s;
        -webkit-transition: -webkit-transform 0.3s ease;
        -webkit-transition-delay: 0.5s;
        transition-delay: 0.5s;
        transition: transform 0.3s ease 0.5s;
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);

        & .bar:nth-last-of-type(1) {
          top: 15px;
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
          -moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
          -o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
          -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
          -webkit-transition-delay: 0.1s, 0.5s;
          transition-delay: 0.1s, 0.5s;
          transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
        }

        & .bar:nth-last-of-type(2) {
          opacity: 0;
        }

        & .bar:nth-last-of-type(3) {
          top: 15px;
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          -moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.7s;
          -o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.7s;
          -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
          -webkit-transition-delay: 0.1s, 0.7s;
          transition-delay: 0.1s, 0.7s;
          transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.7s;
        }
      }

      // span {
      //     width: $nav-btn-w;
      //     height: $nav-btn-h;
      //     background-color: $color-text;
      //     display: inline-block;
      //     position: relative;

      //     &::before,
      //     &::after {
      //         content: "";
      //         position: absolute;
      //         left: 0;
      //         width: $nav-btn-w;
      //         height: $nav-btn-h;
      //         background-color: $color-text;
      //     }

      //     &::before {
      //         top: -0.8rem;
      //     }

      //     &::after {
      //         top: 0.8rem;
      //     }
      // }
    }

    &--sidebar {
      position: fixed;
      background-color: #000000;
      top: 0;
      left: -50%;
      bottom: 0;
      right: 100%;
      z-index: 9;
      padding: auto 3rem;
      transition: all 300ms ease-out;

      &[data-active="on"] {
        left: 0;
        right: 50%;

        @include respond(phone) {
          right: 25%;
        }

        @media only screen and (max-width: 31.25em) {
          right: 10%;
        }
      }
    }

    &--list {
      list-style: none;
      margin: 12rem 3rem 0 3rem;
      font-size: 2rem;

      &-item {
        margin: 0 0 2rem 0;
      }

      &-link {
        color: inherit;
        font-family: inherit;
        text-decoration: none;
      }
    }
  }
}
