.projects {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $section-padding;
  background-image: linear-gradient(to bottom, $color-bg 45%, $color-dark-3);

  &__carousel {
    width: 60%;
    height: 400px;

    @include respond(tab-port) {
      width: 80%;
    }

    @include respond(phone) {
      width: 95%;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white;
    // padding: 10px;
    outline: none;
    z-index: 8;
    // background-color: aqua;

    &--icon {
      position: relative;
      height: 8rem;
      width: 4rem;
      transition: transform 0.25s ease;
      transform-origin: center;
      // padding: 0 1rem;

      &:hover {
        transform: scale(1.1);
      }
    }

    &--icon::before,
    &--icon::after {
      content: "";
      position: absolute;
      background-color: $color-primary;
      width: 4rem;
      height: 0.5rem;
      border-radius: 1rem;
    }

    &--prev {
      left: 0;

      & .projects__arrow--icon:before {
        top: 30%;
        left: 50%;
        transform: translateX(-50%) rotate(-45deg);
      }

      & .projects__arrow--icon:after {
        left: 0;
        top: 61%;
        transform: rotate(45deg);
      }
    }

    &--next {
      right: 0;

      & .projects__arrow--icon:before {
        top: 30%;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }

      & .projects__arrow--icon:after {
        left: 0;
        top: 61%;
        transform: rotate(-45deg);
      }
    }
  }

  &__item {
    &--container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;

      @include respond(tab-port) {
        height: 300px;
      }
    }

    // background-color: $color-dark-2;
    width: 80%;
    border-radius: 3rem;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include respond(phone) {
      width: 70%;
    }

    &--head {
      width: 100%;
      height: 5rem;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      border: 0.1rem solid $color-text;
      display: flex;
      align-items: center;
      padding: 2rem 3rem;
    }

    &--heading {
      font-size: 2.5rem;

      @include respond(tab-port) {
        font-size: 2rem;
      }
    }

    &--img {
      height: 100%;
      flex-grow: 1;
      position: relative;
      // background-image: linear-gradient(to bottom, $color-primary, $color-primary);
      // background-clip: border-box;

      & img {
        height: 100%;
        width: auto;
        object-fit: cover;
      }

      &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;

        &-1 {
          background-color: $color-primary;
          -webkit-mask: url("../images/Centro.png") center / cover no-repeat;
          mask: url("../images/Centro.png") center / cover no-repeat;
        }

        &-2 {
          background-color: $color-primary;
          -webkit-mask: url("../images/Vitpay.png") center / cover no-repeat;
          mask: url("../images/Vitpay.png") center / cover no-repeat;
        }

        &-3 {
          background-color: $color-primary;
          -webkit-mask: url("../images/ThaThrowdown.png") center / cover
            no-repeat;
          mask: url("../images/ThaThrowdown.png") center / cover no-repeat;
        }
      }
    }

    &--details {
      font-size: 2.5rem;
      background-image: linear-gradient(
        to bottom,
        transparent 1%,
        $color-bg 50%,
        #111111c0
      );
      padding: 5rem 0 4rem 0;
      position: absolute;
      bottom: 0;
    }
  }
}
