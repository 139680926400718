.header {
  width: 100%;
  height: 100vh;
  // position: absolute;
  // top: 0;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.9)
    ),
    url("../images/looped-video.gif");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
}

.hero {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
  }

  &__heading {
    font-family: Akira, sans-serif;
    font-size: 6rem;
    margin-bottom: 0.6em;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    transform: translateY(50px);
    opacity: 0;
    animation: fadeInBottom1 1s ease forwards;

    @include fade-in-from-bottom(fadeInBottom1, 50px);

    @include respond(tab-land) {
      font-size: 6rem;
    }

    @include respond(tab-port) {
      font-size: 4.5rem;
    }

    @include respond(phone) {
      font-size: 3rem;
    }
  }

  &__desc {
    font-size: 2.5rem;
    width: 60%;
    text-align: center;
    justify-self: center;
    transform: translateY(20px);
    opacity: 0;
    animation: fadeInBottom2 1s ease 0.5s forwards;

    @include fade-in-from-bottom(fadeInBottom2, 20px);

    @include respond(tab-land) {
      font-size: 2rem;
    }

    @include respond(tab-port) {
      font-size: 1.6rem;
      width: 75%;
    }

    @include respond(phone) {
      font-size: 1.3rem;
      width: 80%;
    }
  }

  &__btn {
    color: $color-primary;
    font-size: 10rem;
    text-decoration: none;
    background-color: transparent;
    border-radius: 50px;
    border: none;
    padding: 2rem;
    margin-top: 4rem;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      transform-origin: center;
      transform: scale(1.05);
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
